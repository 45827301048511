import React, { useState, useEffect, useCallback } from 'react';
import { isAndroid } from 'react-device-detect';

import { Localize } from '@kokolingo/localisation';

import { Icon } from 'src/components/common';
import colors from 'src/theme/colors.scss';
import { mobile } from 'src/utils';

import './index.scss';

const GoogleSmartAppBanner = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const isClosed =
      sessionStorage.getItem('isGoogleSmartAppBannerClosed') === 'true';
    setIsOpen(!isClosed);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    sessionStorage.setItem('isGoogleSmartAppBannerClosed', 'true');
  }, []);

  if (!isOpen || !isAndroid) {
    return null;
  }

  return (
    <div className="google-smart-app-banner ">
      <div className="google-smart-app-banner-child">
        <div className="google-smart-app-banner-icon-placeholder">
          <Icon name="close" color={colors.lightBrown} size={25} />
        </div>
        <div>
          <a
            href={mobile.getGooglePlayUrl()}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleClose}
          >
            <Localize id="mobile.banner.label" />
          </a>
        </div>
        <div className="google-smart-app-banner-icon" onKeyPress={handleClose}>
          <Icon
            name="close"
            onClick={handleClose}
            color={colors.lightBrown}
            size={25}
          />
        </div>
      </div>
    </div>
  );
};

export default GoogleSmartAppBanner;
