const format = (dateTime) => {
  const dateString = dateTime.split('T')[0];

  if (!dateString) {
    return '';
  }

  const date = new Date(dateString);

  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}.`;
};

const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + parseInt(days, 10));
  return result;
};

function getIsPromoDate() {
  const currentDate = new Date();
  const startDate = new Date('2025-02-03');
  const endDate = new Date('2025-02-17');

  return currentDate >= startDate && currentDate <= endDate;
}

export default { addDays, format, getIsPromoDate };
