import { createSelector } from 'reselect';

const getIsSubmitting = createSelector(
  (state) => state.user.isSubmitting,
  (isSubmitting) => isSubmitting
);

const getCheckpoints = createSelector(
  (state) => state.authentication.data?.user?.meta?.checkpoints,
  (checkpoints) => checkpoints
);

const getTotalPoints = createSelector(
  (state) => state.authentication.data?.user?.totalPoints,
  (totalPoints) => totalPoints
);

const getUserMeta = createSelector(
  (state) => state.authentication.data.user.meta,
  (userMeta) => userMeta
);

const getUserPlayedVideos = createSelector(
  getUserMeta,
  (userMeta) => userMeta?.playedVideos || {}
);

const getShowShopModal = createSelector(
  (state) => state.user.showShopModal,
  (showShopModal) => showShopModal
);

export default {
  getCheckpoints,
  getIsSubmitting,
  getUserMeta,
  getUserPlayedVideos,
  getTotalPoints,
  getShowShopModal,
};
