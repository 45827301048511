import { useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';

import { mobile } from 'src/utils';

const AppleSmartAppBanner = () => {
  const metaRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const currentUrl = window.location.href;

    if (!metaRef.current) {
      let meta = document.head.querySelector('meta[name="apple-itunes-app"]');
      if (meta) {
        meta.content = `app-id=${mobile.getAppStoreId()}, app-argument=${currentUrl}`;
      } else {
        meta = document.createElement('meta');
        meta.name = 'apple-itunes-app';
        meta.content = `app-id=${mobile.getAppStoreId()}, app-argument=${currentUrl}`;
        document.head.appendChild(meta);
      }
      metaRef.current = meta;
    } else {
      metaRef.current.content = `app-id=${mobile.getAppStoreId()}, app-argument=${currentUrl}`;
    }
  }, [location.key]);

  return null;
};

export default AppleSmartAppBanner;
